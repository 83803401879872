import React from 'react';
import PropTypes from 'prop-types';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import { usePrefs, useLabels, useRequestData } from 'wsm-common-data';

import InventoryCount from './InventoryCount';

import { ModelImage } from '../ModelImage';

import { getModelTitle } from '../../utils/getModelTitle';
import { getFormattedPrice } from '../../utils/getFormattedPrice';

const CarouselCard = ({ modelObject, modelLink }) => {
	const prefs = usePrefs();
	const labels = useLabels();
	const { locale, widgetName, windowId } = useRequestData();

	const { image, model } = modelObject;
	const {
		showInventoryOnLotCount,
		modelTitleAttributes,
		startingAtPriceLabel,
		showPricing,
		includeDestinationChargeInPrice
	} = prefs;

	const showInventoryCount = showInventoryOnLotCount === 'true';
	const modelClassNames = [
		'model',
		showInventoryCount ? 'showing-count' : null,
		'p-5',
		'd-flex',
		'flex-1',
		'flex-column',
		'justify-content-between'
	];
	const modelTitleClassName = [
		'font-weight-bold',
		'ddc-font-size-large',
		'd-flex',
		'text-center'
	];
	const priceClassNames = [
		'price',
		'ddc-font-size-small',
		'inline-block',
		'justify-content-start',
		'd-flex'
	];
	const modelTitle = getModelTitle(modelTitleAttributes, modelObject);
	const formattedPrice = getFormattedPrice(
		locale,
		includeDestinationChargeInPrice === 'true',
		modelObject
	);

	const trackClick = () => {
		trackEvent(widgetName, windowId, {
			action: 'clicked',
			element: 'Model Image',
			elementCTA: modelTitle,
			result: 'Navigated to model listings'
		});
	};

	return (
		<div className="carousel-card justify-content-center pointer">
			<div className={setClassNames(modelClassNames)}>
				<div className="d-flex flex-column align-items-center model-card-title-container">
					<span className={setClassNames(modelTitleClassName)}>
						{modelTitle}
					</span>
					{showPricing === 'true' && (
						<span className={setClassNames(priceClassNames)}>
							{`${labels.get(startingAtPriceLabel)} `}{' '}
							{formattedPrice}
						</span>
					)}
				</div>

				<a
					href={modelLink}
					onClick={trackClick}
					className="image-container py-5"
					data-testid="model-card-carousel-component-test"
				>
					<ModelImage url={image} altImage={modelTitle} />
				</a>
				<InventoryCount model={model} />
			</div>
		</div>
	);
};

CarouselCard.propTypes = {
	modelObject: PropTypes.shape({
		year: PropTypes.number,
		make: PropTypes.string,
		model: PropTypes.string,
		image: PropTypes.string,
		inventoryCount: PropTypes.number,
		minStartingAtPrice: PropTypes.number
	}).isRequired,
	modelLink: PropTypes.string.isRequired
};

export default CarouselCard;
