import React from 'react';
import { usePrefs, useLabels } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Skeleton from 'wsm-skeleton';
import {
	inventoryCountsSelector,
	loadingInventoryCountsSelector
} from '../../server/selectors';

const InventoryCount = ({ model = '' }) => {
	const prefs = usePrefs();
	const labels = useLabels();
	const { inventoryCountLabel, showInventoryOnLotCount } = prefs;

	const showInventoryCount = showInventoryOnLotCount === 'true';

	const inventoryCounts = useSelector(inventoryCountsSelector);
	const loadingInventoryCounts = useSelector(loadingInventoryCountsSelector);

	const inventoryCount = inventoryCounts[model.trim()] || 0;

	// is 15 px as the font size is 10px (ddc-font-size-xsmall)
	// and the line height is 1.5
	const placeholderHeight = '15px';
	const placeholderWidth = '60px';

	if (loadingInventoryCounts.status) {
		return (
			<div
				data-testid="wsm-skeleton"
				className="d-flex justify-content-center m-0"
			>
				<Skeleton height={placeholderHeight} width={placeholderWidth} />
			</div>
		);
	} else if (loadingInventoryCounts.error) {
		return (
			<div
				data-testid="inventory-count-error"
				style={{
					height: placeholderHeight,
					width: placeholderWidth
				}}
			/>
		);
	}

	return (
		<span className="font-weight-extended-bold ddc-font-size-small text-center">
			{showInventoryCount && (
				<div className="touch-inventory-count ddc-font-size-xsmall font-weight-bold ">
					<span>{inventoryCount}</span>
					<span className={setClassNames(['inventory-count-label'])}>
						{` ${labels.get(inventoryCountLabel)}`}
					</span>
				</div>
			)}
		</span>
	);
};

InventoryCount.propTypes = {
	model: PropTypes.string.isRequired
};

export default InventoryCount;
