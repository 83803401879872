/**
 * Filters out vehicles that do not have inventory counts greater than zero.
 *
 * @param {Object} vehicles - The dictionary of vehicles grouped by type.
 * @param {Object} inventoryCounts - The dictionary of inventory counts by vehicle model.
 * @returns {Object} - A new dictionary of vehicles with only those that have inventory.
 * @example
 * const vehicles = {
 *   suv: [
 *     {
 *       model: 'Model X',
 *     },
 *     {
 *       model: 'Model Y',
 *     }
 *   ],
 *   sedan: [
 *     {
 *       model: 'Model S',
 *     }
 *   ]
 * };
 *
 * const inventoryCounts = {
 *   'Model X': 5,
 *   'Model Y': 0,
 *   'Model S': 0
 * };
 *
 * const filteredVehicles = filterVehiclesWithoutInventory(vehicles, inventoryCounts);
 * console.log(filteredVehicles);
 * // Output:
 * // {
 * //   suv: [
 * //     {
 * //       model: 'Model X',
 * //       inventoryCount: 5
 * //     }
 * //   ]
 * // }
 */
export default function filterVehiclesWithoutInventory(
	vehicles,
	inventoryCounts
) {
	const filteredVehicles = {};
	// Convert the vehicles object into an array of [key, value] pairs
	const vehicleEntries = Object.entries(vehicles);

	// filter out vehicles with no inventory
	vehicleEntries.forEach(([groupName, vehicleList]) => {
		filteredVehicles[groupName] = vehicleList.filter((vehicle) => {
			const { model } = vehicle;
			return (inventoryCounts[model.trim()] ?? 0) > 0;
		});
	});
	const filteredVehicleEntries = Object.entries(filteredVehicles);
	const newVehicleDictionary = {};

	// filter out groups with no vehicles
	filteredVehicleEntries.forEach(([groupName, vehicleList]) => {
		if (vehicleList.length > 0) {
			newVehicleDictionary[groupName] = vehicleList;
		}
	});
	return newVehicleDictionary;
}
