import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { usePrefs } from 'wsm-common-data';

import { inventoryCountsSelector } from '../server/selectors';
import { BodyStyleContainer } from './BodyStyleContainer';

import Tab from './Tab';
import Tabs from './Tabs';
import filterVehiclesWithoutInventory from '../utils/filterVehiclesWithoutInventory';
import { groupUniqueVehicles } from '../utils/groupUniqueVehicles';

export const TabbedBodyStyles = ({ data }) => {
	const {
		tabOrder,
		showAllModelsTab,
		displayStyle,
		excludeModelsWithoutInventory
	} = usePrefs();
	const tabSliderRef = React.useRef();
	const inventoryCounts = useSelector(inventoryCountsSelector);

	let vehicles;

	if (
		excludeModelsWithoutInventory === 'true' &&
		inventoryCounts &&
		Object.keys(inventoryCounts).length
	) {
		vehicles = filterVehiclesWithoutInventory(data, inventoryCounts);
	} else {
		vehicles = { ...data };
	}

	const gvBodyStyles = Object.keys(vehicles);
	let tabNames = gvBodyStyles;

	if (tabOrder) {
		tabNames = tabOrder.split(',').reduce((tabNamesArray, tabName) => {
			const gvBodyStyle = gvBodyStyles.find(
				(dataBodyStyle) =>
					dataBodyStyle.toLowerCase() === tabName.trim().toLowerCase()
			);
			if (gvBodyStyle && !tabNamesArray.includes(gvBodyStyle)) {
				tabNamesArray.push(gvBodyStyle);
			}
			return tabNamesArray;
		}, []);
	}

	let defaultTab;
	if (showAllModelsTab === 'true' && vehicles) {
		if (displayStyle === 'largeVehicle') {
			tabNames.push('All');
		} else {
			tabNames.unshift('All');
		}
		defaultTab = 'All';

		const allCars = groupUniqueVehicles(vehicles);

		vehicles.All = [...allCars];
	}

	if (!defaultTab) {
		[defaultTab] = tabNames;
	}

	const [activeTab, setActiveTab] = useState(tabNames[0]);

	const getTabContent = (tabTitle) => {
		const tabVehicles = vehicles[tabTitle];
		if (tabVehicles) {
			return <BodyStyleContainer vehiclesArray={tabVehicles} />;
		} else {
			return null;
		}
	};

	const tabArray = React.Children.toArray(
		tabNames.map((tabName) => (
			<Tab
				title={tabName}
				eventKey={tabName}
				tabClassName="tab-item ddc-font-size-xsmall mx-2"
			>
				{getTabContent(tabName)}
			</Tab>
		))
	);

	return (
		<Tabs
			ref={tabSliderRef}
			activeKey={activeTab}
			onSelect={setActiveTab}
			id="tabbed-model-selector"
			defaultActiveKey={defaultTab}
		>
			{tabArray}
		</Tabs>
	);
};

TabbedBodyStyles.propTypes = {
	data: PropTypes.objectOf(
		PropTypes.arrayOf(
			PropTypes.shape({
				year: PropTypes.number,
				make: PropTypes.string,
				model: PropTypes.string,
				gvBodyStyle: PropTypes.string,
				image: PropTypes.string,
				inventoryCount: PropTypes.number
			})
		)
	).isRequired
};
